<template>
  <div
    class="ap_check_task_index bbox"
  >
    <div class="summary_wrapper bbox">
      <div
        v-if="ptask"
        class="summary_group bbox d-flex align-center justify-space-between"
      >
        <div class="info_group bbox">
          <div class="title_group d-flex align-center">
            <div class="title flex-shrink-0 mr-10">{{ptask.name}}</div>

            <div
                class="consult_group cp un_sel d-flex align-center mr-15"
                v-if="ptask.price !== 0"
                @click.stop="doConsulting();"
            >
              <img
                  class="icon mr-3"
                  src="~@/assets/images/shopping_mail/consult_icon.png"
              >
              <div class="consult"
              >购买咨询</div>
            </div>
            <div
              v-if="0"
              class="collect_group cp un_sel d-flex align-center mr-15"
              @click.stop="touchCollect()"
            >
              <img
                  class="icon mr-3"
                  src="~@/assets/images/shopping_mail/collect_icon.png"
                  v-if="!ptask.collectionflag"
              >
              <img
                  class="icon mr-3"
                  src="~@/assets/images/shopping_mail/collect_icon_active.png"
                  v-if="ptask.collectionflag"
              >
              <div class="collect"
              >{{ptask.collectionflag ? '已收藏' : '收藏'}}</div>
            </div>
          </div>
          <div
              class="sub_title flex-shrink-0 pt-10 d-flex align-start"

          >
            <div class="key flex-shrink-0 pr-5">简介：</div>
            <div class="content"
              v-html="ptask.introduction"
            ></div>
          </div>
          <div class="details pt-6"
            v-if="0"
          >PMCS-E102
            《会展策划与管理》配套实践任务模块
            《会展策划与管理》是学习会展经济与管理、会展策划与管理、会展服务与管理相关专业的必修课程，所有从事会展及相关工作的岗位均需要了解整个知识体系结构以及策划与管理原理。
            任务总数：65
            适用教材：
            《会展策划与管理》王春雷-高等教育出版社-2018年12月
            《会展业导论》王春雷（译）-重庆大学出版社-2018年11月
            《会展项目管理》刘大可-中国人民大学出版社-2017年4月
            《会展项目管理-理论、方法与实践》江金波-清华大学出版社-2014年2月
          </div>
          <div
            v-if="0"
            class="data_group pt-10 d-flex align-center"
          >
            <div class="category mr-20"
                 v-if="0"
            >会展专业 / 会展营销 / 市场营销</div>
            <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon mr-4">
            <div
              class="text pr-20"
            >
              {{ $moment(ptask.createTime).format('YYYY年MM月DD日') }}
            </div>
            <template
                v-if="0"
            >
              <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
              <div class="text pr-20">9876</div>
              <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
              <div class="text">9.2w</div>
            </template>
          </div>
          <div class="lecturer_group d-flex align-center mt-10">
            <div class="text mr-10">讲师:</div>
            <img
                :src="`${$avatar_url}${ptask.createuserthumbnail}@!small200`"
                alt=""
                class="avatar mr-8"
            >
            <div class="name">{{ptask.createusername}}</div>
          </div>
        </div>
        <div class="fun_group d-flex align-center justify-center flex-shrink-0 ml-20">
          <div
            class="button btn_light check"
            @click.stop="goToCheck()"
          >
            前往妙行查看
          </div>
        </div>
        <div
          v-if="payment !== false && 0"
          class="payment_group flex-shrink-0 ml-20"
        >
          <div class="sold_out pr-20"
            v-if="!ptask.showflag"
          >未上架</div>
          <template
              v-else
          >
            <div class="price"><span class="text">售价：</span><span class="number">¥{{ptask.price/100}}</span><span class="buy_hint"
                                                                                                                  v-if="ptask.buy"
            >[已购买]</span></div>

            <div class="button_group d-flex align-center mt-20">
              <div class="button add mr-10"
                   v-if="0"
              >加入定制清单</div>
              <div class="button buy"
                   @click.stop="buyNow();"
              >{{ptask.price === 0 ? '免费领取' : '立即购买'}}</div>
            </div>

          </template>
        </div>
      </div
      >
    </div>
    <div class="task_wrapper bbox">
      <div class="task_group mt-20">
        <div class="mask"></div>
        <task
            class="task_details_group"
            v-if="loading_init"
            :max_width="'none'"
            :max_height="'none'"
            :box_shadow="'box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)'"
            :show_intro="false"
            :shopping_mail="true"
            :border="'border: 1px solid #E8EEFF'"
            :sell="true"
        ></task>
      </div>
    </div>
  </div>
</template>

<script>
import Task from '@/components/Task/Container.vue'
import { PTask } from '@/types'
export default {
  props: {
    task_id: {
      type: Number,
      default: undefined,
    },
    payment: {
      type: Boolean,
      default: undefined,
    }
  },
  components: {
    Task
  },
  data(){
    return {
      loading_init: false,
      ptask: undefined,
      lecturer_task: [],
      task_line_number: 5, // 任务单行数据
    }
  },
  watch: {
    '$route.params.task_id': function(val){
      if(val){
        // console.log(val);
        this.doClear();
        this.$nextTick(function(){
          this.init();
        })
      }
    }
  },
  mounted(){
    console.log(this.$route)
    this.loading_init = false;
    this.init();
    // // console.log(this.payment);
  },
  methods: {
    goToCheck() {
      window.open(`/fun_template/shopping_mail/task_detail/${this.$route.query.taskId}/${Date.now()}?toResource=1`, '_blank')
    },
    doClear(){
      this.$set(this, 'ptask', undefined);
      this.$set(this, 'lecturer_task', []);
    },
    async init(){
      const {$route} = this;
      const {params} = $route;
      const {task_id} = params;
      await this.getTask({task_id});
      this.loading_init = true;
      // this.$nextTick(async function(){
      //   // this.getTaskLineNumber();
      //   // this.getLecturerShopTask();
      // })
    },
    async getTask(){
      // const {task_id:taskId} = params;
      const { $route } = this
      const { query } = $route
      const { taskId } = query
      const res = await this.$api.shopping_mail.getTask({ taskId, url: '/projectOfficialWebsite/taskDetail' })
      const {message, contents} = res;
      // // console.log(contents);
      if(message === 'success'){
        const {task} = contents;
        this.$set(this, 'ptask', new PTask(task))
        // // console.log(task);
      }
      return res;
    },
    async getLecturerShopTask(){
      const res = await this.ptask.getLecturerShopTask({start: this.lecturer_task.length});
      if(res){
        const {tasks} = res;
        const task_list = this.$types.transformObjectForList({
          list: tasks,
          obj: PTask,
        })
        this.$set(this, 'lecturer_task', task_list);
      }
    },
    getTaskLineNumber(){
      const unit_width                     = 200 + 16;
      const task_list                      = this.$refs.task_list;
      const {clientWidth: task_list_width} = task_list;
      // // console.log(task_list_width, unit_width);
      const num = Math.floor(task_list_width/unit_width);
      this.task_line_number = num;
      // console.log(num);
    },
    /**
     * 教学包预览任务
     */
    viewTask(item){
      // this.$emit('handlePushNavigation', {type: 'task', task_data: item, payment: true,})
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
    doConsulting(){
      this.$consulting({
        title: '咨询购买',
        subtitle: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
        enquireType: 1,
        paramId: this.task_id,
      })
      // this.$api.shopping_mail.doConsulting();
    },
    async buyNow(){
      const {ptask} = this;
      const {userId} = this.$tools.getCookie('user');
      const {buy, price, signUserId} = ptask;
      if(userId === signUserId){
        this.$notice({desc: '无法购买自己的任务模板'});
        return ;
      }
      if(price === 0){
        if(buy){
          this.$notice({desc: '您已购买过此任务模板'});
          return;
        }
        const res = await ptask.freeToBuy();
        if(res){
          this.$notice({desc: '购买成功', type: 'success'});
        }else{
          this.$notice({desc: '失败'});
        }
        return;
      }
      let title = '填写订单信息';
      this.$buy_now({
        title,
        task: this.ptask,
      });
    },
    touchCollect(){

      const {ptask} = this
      const {collectionflag} = ptask
      if(collectionflag){
        // 取消收藏
        ptask.removeCollect()
        return
      }

      // 收藏
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }

      ptask.collect()

    }
  }
}
</script>

<style lang="scss" scoped>
.ap_check_task_index{
  @include scrollbar;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  position: relative;
  overflow: auto;
  .summary_wrapper{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
  }
  .summary_group{
    padding         : 30px;
    background-color: #fff;
    .info_group{
      flex: 1;
    }
    .avatar{
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50%;
    }
    .picture_group{
      margin-right: 30px;
      width       : 30%;
      max-width   : 416px;
    }
    .picture{
      width: 100%;
      // max-width: 416px;
      // height: 234px;
      display      : block;
      border-radius: 4px;
    }
    .category{
      font-size  : 12px;
      color      : #AAAAAA;
      line-height: 16px;
    }
    .info_group{
      .data_group{
        font-size  : 12px;
        color      : #AAAAAA;
        line-height: 16px;
      }
    }
    .title{
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      line-height: 35px;
    }
    .consult_group{
      .consult{
        @include transition;
        font-size: 14px;
        color: #82929C;
      }
      // border-bottom: 1px solid transparent;
      &:hover{
        .consult{
          color: $main_blue;
        }
        // border-bottom-color: $main_blue;
      }
    }
    .collect_group{
      .collect{

        @include transition;
        font-size: 14px;
        color: #82929C;
      }
      &:hover{
        .collect{
          color: $main_blue;
        }
      }
    }
    .sub_title{
      font-size  : 14px;
      color      : #333333;
      line-height: 19px;
    }
    .details{
      @include scrollbar;
      overflow-y : auto;
      flex       : 1;
      width      : 100%;
      font-size  : 14px;
      color      : #666666;
      line-height: 26px;
      max-height : 26*2px;
      word-break : break-all;
    }
    .lecturer_group{
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    .fun_group{
      .check{
        line-height: 48px;
        padding: 0 20px;
        border-radius: 4px;
      }
    }
  }
  .task_wrapper{
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0 20px;
  }
  // 任务
  .task_group{
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.0600);
    border-radius: 4px;
    border: 1px solid #E8EEFF;
    .task_details_group{
      position: relative;
      z-index : 2;
    }
    .mask{
      position: absolute;
      z-index : 3;
      width   : 100%;
      height  : 100%;
      left    : 0;
      top     : 0;
    }
  }
  .section{
  }
}
</style>